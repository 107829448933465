import React from "react";
import Carousel from "../components/Carousel";
import Footer from "../components/Footer";
import Nav from "../components/Nav";
import BarGallery from "../components/Restaurant/BarGallery";
import About from "../components/Restaurant/About";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";

import slide1 from "../images/restaurant/carousel/2L.jpg";
import slide2 from "../images/restaurant/carousel/4L.jpg";
import slide3 from "../images/restaurant/carousel/5L.jpg";
import slide4 from "../images/restaurant/carousel/6L.jpg";
import slide5 from "../images/restaurant/carousel/1L.jpg";
import slide6 from "../images/restaurant/carousel/3L.jpg";

export default function Restaurant() {
  const { t } = useTranslation("translation", { useSuspense: false });

  const content = { heading: t("laNoce.title") };

  const slides = [slide1, slide2, slide3, slide4, slide5, slide6];

  return (
    <>
      <Helmet title="La Noce - Restaurant & Pub" defer={false} />
      <Nav />
      <Carousel content={content} slides={slides} />
      <About />
      <BarGallery />
      <Footer />
    </>
  );
}
