import React from "react";
import styles from "./about.module.css";
import { useTranslation } from "react-i18next";

import espadon from "../../images/restaurant/espadon.jpg";
import filet from "../../images/restaurant/filet.jpg";
import salad from "../../images/restaurant/salad.jpg";

export default function About() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <section className={styles.aboutSection}>
      <h1 className={styles.heading}>{t("laNoce.restaurant")}</h1>
      <div className={`container ${styles.container}`}>
        <p className={styles.restaurant}>{t("laNoce.restaurantText")}</p>
        <div className={styles.imgContainer}>
          <img
            className={`${styles.dishes} ${styles.dish1}`}
            src={espadon}
            alt="espadon sauce provincial"
          />

          <img
            alt="filet sauce bearnaise"
            className={`${styles.dishes} ${styles.dish2}`}
            src={filet}
          />

          <img
            alt="salad la noce"
            className={`${styles.dishes} ${styles.dish3}`}
            src={salad}
          />
        </div>
      </div>
    </section>
  );
}
