import React from "react";
import { Player } from "video-react";
import styles from "./barGallery.module.css";
import video from "../../videos/cocktails.mp4";
import { useTranslation } from "react-i18next";

export default function BarGallery() {
  const { t } = useTranslation("translation", { useSuspense: false });
  return (
    <section className={styles.section}>
      <div className={styles.grid}>
        <div className={`${styles.item} ${styles.itemLarge}`}>
          <div className={styles.itemDetails}>Perroquet</div>
        </div>
        <div className={styles.headingContainer}>
          <h1 className={`${styles.heading}`}>{t("laNoce.bar")}</h1>
        </div>
        <div className={`${styles.item} ${styles.itemLarge}`}>
          <div className={styles.itemDetails}>Mojito</div>
        </div>
        <div className={`${styles.item} ${styles.itemMedium}`}>
          <div className={styles.itemDetails}>zombie brain</div>
        </div>
      </div>
      <div className={styles.playerContainer}>
        <Player playsInline src={video} />
      </div>
    </section>
  );
}
